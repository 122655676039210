.App {
  /* text-align: center; */
  width: 100%;
}

.App-header {
  /* background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; */
  display: flex;
  background-color: #e4943b;
	height: 200px;
  flex-wrap: wrap;
	/* padding: 20px; */
}

.col-sm-4 {
  width: 33%;
  max-width: 34%;
}

.logo {
  text-align: center;
  /* pointer-events: none; */
}

.bar {
  position: relative;
  margin-top: 150px;
  text-align: left ; 
}

.nav-link {
	padding: 0.4rem 0.8rem;
	background-color: white;
	border-radius: 3px;
	color: #e4943b;
	text-decoration: none !important;
	margin: 5px;
}

.nav-link:hover {
	background-color: transparent;
	color: white;
	border: 2px solid white;
}

.footer {
  background-color: #e4943b;
	height: auto;
  display: flex;
  flex-wrap: wrap;
  /* margin-top: 30px; */
}

.col-sm-6 {
  width: 50%;
}

.email {
  width: 30%;
  text-align: center;
  font-weight: bold;
}

.contactUs {
  /* text-align: center; */
  font-weight: bold;
  padding-left: 4%;
}